import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './bootstrap';

// vue-flatpickr-component
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import '@/assets/sass/forms/custom-flatpickr.css';

Vue.use(flatPickr);

// multi select
import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

//bootstrap vue
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

//perfect scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar';
Vue.use(PerfectScrollbar);

//vue-scrollactive
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

//vue-meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

//Sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#12B09B',
  cancelButtonColor: '#e7515a',
};
Vue.use(VueSweetalert2, options);

//portal vue
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

//vue-i18n
import i18n from './i18n';

import './vee-validate';

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

// set default settings
import appSetting from './app-setting';

import VueHtml2pdf from 'vue-html2pdf';
Vue.use(VueHtml2pdf);

import shortKey from 'vue-shortkey';
Vue.use(shortKey);

import VueRecaptcha from 'vue-recaptcha';
Vue.use(VueRecaptcha);

// evenbus
export const eventBus = new Vue();

import moment from 'moment';
import 'moment-timezone';
import { dateFormatter } from '@/utils/date';
import { getDocFormat } from '@/utils/localStorageUtil';

// Add Moment.js and Moment Timezone to the Vue prototype
Vue.prototype.$moment = moment;
Vue.prototype.$moment.tz = moment.tz;

Vue.prototype.$appSetting = appSetting;

Vue.prototype.$sidebarEnable = false;

Vue.filter('toTwoDecimalPlaces', function (value) {
  if (value) {
    // Convert the number to two decimal places
    let fixedValue = Number(value).toFixed(2);

    // Add commas as thousands separators
    return parseFloat(fixedValue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else {
    return '0.00';
  }
});

// Vue filter to format date as "YYYY-MM-DD"
Vue.filter('formatDate', function (dateString) {
  return dateFormatter(dateString, getDocFormat()?.yearType);
});
const resizeObserverLoopErr = /ResizeObserver loop limit exceeded/;

window.addEventListener('error', (e) => {
  if (resizeObserverLoopErr.test(e.message)) {
    // Ignore ResizeObserver errors
    e.stopImmediatePropagation();
  }
});

window.addEventListener('unhandledrejection', (e) => {
  if (resizeObserverLoopErr.test(e.reason)) {
    // Ignore ResizeObserver errors
    e.stopImmediatePropagation();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
