import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import VueRouterBackButton from 'vue-router-back-button';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'service-menu',
    component: () => import('../views/auth/login_edit.vue'),
    meta: { layout: 'auth' },
  },

  //dashboard

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard.vue'),
  },

  {
    path: '/index2',
    name: 'index2',
    component: () => import(/* webpackChunkName: "index2" */ '../views/index2.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "index2" */ '../views/index.vue'),
  },

  //  Home Page

  {
    path: '/home-page',
    name: 'home-page',
    component: () => import(/* webpackChunkName: "้home-page" */ '../views/homepage/list.vue'),
  },

  //  company Create

  {
    path: '/company-create',
    name: 'company-create',
    component: () => import(/* webpackChunkName: "company-create" */ '../views/homepage/create.vue'),
  },

  // compant edit

  {
    path: '/company/:id/edit',
    name: 'company-edit',
    component: () => import(/* webpackChunkName: "company-create" */ '../views/homepage/create.vue'),
  },

  // Sign In v.1

  // {
  //     path: '/signin',
  //     name: 'signin',
  //     component: () => import(/* webpackChunkName: "sign in" */ '../views/register/signin.vue'),
  //     meta: { layout: 'auth' }
  // },

  // Sign Up v.1

  // {
  //     path: '/signup',
  //     name: 'signup',
  //     component: () => import(/* webpackChunkName: "sign up" */ '../views/register/signup.vue'),
  //     meta: { layout: 'auth' }
  // },

  // Sign In v.2

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/login_edit.vue'),
    meta: { layout: 'auth' },
  },

  // Sign Up v.2

  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/register_edit.vue'),
    meta: { layout: 'auth' },
  },

  // Reset Password

  {
    path: '/password-recovery',
    name: 'password-recovery',
    component: () => import(/* webpackChunkName: "password recovery" */ '../views/auth/pass-recovery-edit.vue'),
    meta: { layout: 'auth' },
  },

  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "password recovery" */ '../views/auth/resetPassword.vue'),
    meta: { layout: 'auth' },
  },

  // Add customer

  {
    path: '/add-customer',
    name: 'add-customer',
    component: () => import(/* webpackChunkName: "addCustomer" */ '../views/addCustomer/create.vue'),
  },

  // add vendor

  {
    path: '/cost/add-vendor',
    name: 'add-vendor',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/costs/add-vendor.vue'),
  },

  // Quotation

  {
    path: '/incomes/quotation',
    name: 'quotation',
    component: () => import(/* webpackChunkName: "incomes-quotation" */ '../views/quotations/list.vue'),
  },

  // Quotation Create

  {
    path: '/incomes/quotation/create',
    name: 'create-quotation',
    component: () => import(/* webpackChunkName: "incomes-quotation-create" */ '../views/quotations/create.vue'),
  },

  {
    path: '/incomes/quotations/:id/edit',
    name: 'edit-quotation',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/quotations/create.vue'),
  },

  // Quotation Preview

  {
    path: '/incomes/quotations/preview',
    name: 'preview-quotation',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/quotations/preview.vue'),
  },

  // Invoice Preview

  // {
  //     path: '/incomes/invoices/preview',
  //     name: 'preview-quotation-',
  //     component: () => import(/* webpackChunkName: "quotation" */ '../views/invoices/preview.vue')
  // },

  {
    path: '/incomes/quotations/:id/preview',
    name: 'edit-quotation-preview',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/quotations/preview.vue'),
  },

  // Invoice

  {
    path: '/incomes/invoice',
    name: 'invoice',
    component: () => import(/* webpackChunkName: "incomes-invoice" */ '../views/invoices/list.vue'),
  },

  // Invoice Create

  {
    path: '/incomes/invoice/create',
    name: 'invoice-create',
    component: () => import(/* webpackChunkName: "incomes-invoice-create"*/ '../views/invoices/create.vue'),
  },
  {
    path: '/incomes/invoice/:id/edit',
    name: 'edit-invoice',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/invoices/create.vue'),
  },
  {
    path: '/incomes/invoice/:id/preview',
    name: 'edit-invoice-preview',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/invoices/preview.vue'),
  },

  // Add-Product

  {
    path: '/add-product',
    name: 'add-product',
    component: () => import(/* webpackChunkName: "addProduct" */ '../views/addProduct/create.vue'),
  },

  // Billing note

  {
    path: '/incomes/billing-note',
    name: 'billing-note',
    component: () => import(/* webpackChunkName: "incomes-billing-note" */ '../views/billingNotes/list.vue'),
  },

  // Billing note Create

  {
    path: '/incomes/billing-note/create',
    name: 'billing-note-create',
    component: () => import(/* webpackChunkName: "incomes-billing-note-create" */ '../views/billingNotes/create.vue'),
  },
  {
    path: '/incomes/billing-note/:id/edit',
    name: 'edit-billing-note',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/billingNotes/create.vue'),
  },
  {
    path: '/incomes/billing-note/:id/preview',
    name: 'edit-billing-note-preview',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/billingNotes/preview.vue'),
  },

  // Receipt

  {
    path: '/incomes/receipt',
    name: 'receipt',
    component: () => import(/* webpackChunkName: "incomes-receipt" */ '../views/receipts/list.vue'),
  },

  // Receipt Create

  {
    path: '/incomes/receipt/create',
    name: 'receipt-create',
    component: () => import(/* webpackChunkName: "incomes-receipt-create" */ '../views/receipts/create.vue'),
  },
  {
    path: '/incomes/receipt/:id/edit',
    name: 'edit-receipt',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/receipts/create.vue'),
  },
  {
    path: '/incomes/receipt/:id/preview',
    name: 'edit-receipt-preview',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/receipts/preview.vue'),
  },
  // Credit note

  {
    path: '/incomes/credit-note',
    name: 'credit-not',
    component: () => import(/* webpackChunkName: "incomes-credit-not" */ '../views/creditNote/list.vue'),
  },

  // Credit note Edit

  {
    path: '/incomes/credit-note/:id/edit',
    name: 'credit-not-edit',
    component: () => import(/* webpackChunkName: "incomes-credit-not-edit" */ '../views/creditNote/edit.vue'),
  },

  // Debit note

  {
    path: '/incomes/debit-note',
    name: 'debit-note',
    component: () => import(/* webpackChunkName: "incomes-debit-note" */ '../views/debitNote/list.vue'),
  },

  // Debit note Edit

  {
    path: '/incomes/debit-note/:id/edit',
    name: 'debit-note-edit',
    component: () => import(/* webpackChunkName: "incomes-debit-note-edit" */ '../views/debitNote/edit.vue'),
  },

  //  Upload template file

  {
    path: '/settings/upload-template-file',
    name: 'upload-template-file',
    component: () => import(/* webpackChunkName: "settings-upload-template-file" */ '../views/forms/upload-template-file.vue'),
  },

  //  Document format

  {
    path: '/settings/document-format',
    name: 'settings-format',
    component: () => import(/* webpackChunkName: "settings-document-format" */ '../views/docFormat/list.vue'),
  },

  //  Purchase requisition

  {
    path: '/purchase/purchase-requisition',
    name: 'purchase requisition',
    component: () => import(/* webpackChunkName: "purchase-purchase-requisition" */ '../views/purchaseRequisitions/list.vue'),
  },

  //  Purchase requisition Create

  {
    path: '/purchase/purchase-requisition/create',
    name: 'purchase-requisition-create',
    component: () => import(/* webpackChunkName: "purchase-purchase-requisition-create" */ '../views/purchaseRequisitions/create.vue'),
  },

  //  Purchase requisition Edit

  {
    path: '/purchase/purchase-requisition/:id/edit',
    name: 'purchase-requisition-edit',
    component: () => import(/* webpackChunkName: "purchase-purchase-requisition-create" */ '../views/purchaseRequisitions/create.vue'),
  },

  //  Purchase order

  {
    path: '/purchase/purchase-order',
    name: 'purchase-order',
    component: () => import(/* webpackChunkName: "purchase-purchase-order" */ '../views/purchaseOrders/list.vue'),
  },

  //  Purchase order Create

  {
    path: '/purchase/purchase-order/create',
    name: 'purchase order create',
    component: () => import(/* webpackChunkName: "purchase-purchase-order-create" */ '../views/purchaseOrders/create.vue'),
  },

  //  Purchase order Edit

  {
    path: '/purchase/purchase-order/:id/edit',
    name: 'purchase order edit',
    component: () => import(/* webpackChunkName: "purchase-purchase-order-create" */ '../views/purchaseOrders/create.vue'),
  },

  //  Goods receipt

  {
    path: '/purchase/goods-receipt',
    name: 'goods-receipt',
    component: () => import(/* webpackChunkName: "purchase-goods-receipt" */ '../views/goodsReceipts/list.vue'),
  },

  //  Goods receipt Create

  {
    path: '/purchase/goods-receipt/create',
    name: 'goods-receipt-create',
    component: () => import(/* webpackChunkName: "purchase-goods-receipt-create" */ '../views/goodsReceipts/create.vue'),
  },

  //  Goods receipt Edit

  {
    path: '/purchase/goods-receipt/:id/edit',
    name: 'goods-receipt-edit',
    component: () => import(/* webpackChunkName: "purchase-goods-receipt-create" */ '../views/goodsReceipts/create.vue'),
  },

  //  Payable note

  {
    path: '/purchase/payable-note',
    name: 'payable-note',
    component: () => import(/* webpackChunkName: "purchase-payable-note" */ '../views/payableNotes/list.vue'),
  },

  //  Payable note Create

  {
    path: '/purchase/payable-note/create',
    name: 'payable-note-create',
    component: () => import(/* webpackChunkName: "purchase-payable-note-create" */ '../views/payableNotes/create.vue'),
  },

  //  Payable note Edit

  {
    path: '/purchase/payable-note/:id/edit',
    name: 'payable-note-edit',
    component: () => import(/* webpackChunkName: "purchase-payable-note-create" */ '../views/payableNotes/create.vue'),
  },

  //  Withholding tax

  {
    path: '/cost/withholding-tax',
    name: 'withholding-tax',
    component: () => import(/* webpackChunkName: "cost-withholding-tax" */ '../views/withholdingTax/list.vue'),
  },

  //  Withholding tax Create

  {
    path: '/cost/withholding-tax/create',
    name: 'withholding-tax-create',
    component: () => import(/* webpackChunkName: "cost-withholding-tax-create" */ '../views/withholdingTax/create.vue'),
  },

  //  Withholding tax Edit

  {
    path: '/cost/withholding-tax/:id/edit',
    name: 'withholding-tax-edit',
    component: () => import(/* webpackChunkName: "cost-withholding-tax-create" */ '../views/withholdingTax/create.vue'),
  },

  // report //

  // monthly summary

  {
    path: '/report/monthly-summary',
    name: 'monthly-summary',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-month.vue'),
  },

  // sale summary

  {
    path: '/report/sale-summary',
    name: 'sale-summary',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-sale.vue'),
  },

  // receipt

  {
    path: '/report/receipt',
    name: 'payout-report',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-receipt.vue'),
  },

  // cash flow

  {
    path: '/report/cash-flow',
    name: 'cash-flow',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-cash-flow.vue'),
  },

  // accounts receivable

  {
    path: '/report/accounts-receivable',
    name: 'accounts-receivable',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-receivable.vue'),
  },

  // Trade accounts payable

  {
    path: '/report/trade-accounts-payable',
    name: 'trade-accounts-payable',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-payable.vue'),
  },

  // Sale tax

  {
    path: '/report/sale-tax',
    name: 'sale-tax',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-sale-tax.vue'),
  },

  // Purchase tax

  {
    path: '/report/purchase-tax',
    name: 'purchase-tax',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-purchase.vue'),
  },

  // Inventory report

  {
    path: '/report/inventory-report',
    name: 'inventory-report',
    component: () => import(/* webpackChunkName: "contact" */ '../views/report/report-inventory.vue'),
  },

  // Pending document

  {
    path: '/pending-document',
    name: 'pending-document',
    component: () => import(/* webpackChunkName: "pendingDocument" */ '../views/pendingDocs/list.vue'),
  },

  // Document submission history

  {
    path: '/document-history',
    name: 'document-history',
    component: () => import(/* webpackChunkName: "documentHistory" */ '../views/docHistory/list.vue'),
  },

  //  Company setting

  {
    path: '/settings/company-setting',
    name: 'company-setting',
    component: () => import(/* webpackChunkName: "setting-company-setting" */ '../views/companySetting/setting.vue'),
  },

  //  Company setting edit

  {
    path: '/settings/company-setting/:id/edit',
    name: 'company-setting-edit',
    component: () => import(/* webpackChunkName: "setting-company-setting" */ '../views/companySetting/setting.vue'),
  },

  //  Branch informations

  {
    path: '/settings/branch-information-list',
    name: 'branch-information',
    component: () => import(/* webpackChunkName: "setting-branch-information-list" */ '../views/branchInformations/list.vue'),
  },

  //  Branch informations Create

  {
    path: '/settings/branch-information/create',
    name: 'branch-information-create',
    component: () => import(/* webpackChunkName: "setting-branch-information-create" */ '../views/branchInformations/create.vue'),
  },

  {
    path: '/settings/branch-information/:id/edit',
    name: 'branch-information-edit',
    component: () => import(/* webpackChunkName: "unit" */ '../views/branchInformations/create.vue'),
  },

  // {
  //     path: '/settings/branchInformationSetting',
  //     name: 'branch-information-setting',
  //     component: () => import('../views/settingMenu/branch-information-setting.vue')
  // },

  // Customer and distributor

  {
    path: '/settings/customerView',
    name: 'customer-view',
    component: () => import(/* webpackChunkName: "setting-customer" */ '../views/listCustomers/list.vue'),
  },

  // Customer and distributor Create

  {
    path: '/settings/customer/create',
    name: 'customer-create',
    component: () => import(/* webpackChunkName: "setting-customer-create" */ '../views/listCustomers/create.vue'),
  },

  {
    path: '/settings/customer/:id/edit',
    name: 'customer-edit',
    component: () => import(/* webpackChunkName: "setting-customer-create" */ '../views/listCustomers/create.vue'),
  },

  {
    path: '/settings/userList',
    name: 'user-list',
    component: () => import('../views/settingMenu/user-list.vue'),
  },
  {
    path: '/settings/userSetting',
    name: 'user-setting',
    component: () => import('../views/settingMenu/user-setting.vue'),
  },

  // Personal info

  {
    path: '/settings/personal-info-setting',
    name: 'personal-info-setting',
    component: () => import(/* webpackChunkName: "settings-personal-info-setting" */ '../views/personalInfo/create.vue'),
  },

  // Change password

  {
    path: '/settings/personal-info-setting/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "settings-personal-info-setting-change-password" */ '../views/personalInfo/change_pass.vue'),
  },

  // System

  {
    path: '/settings/system',
    name: 'system-connect',
    component: () => import(/* webpackChunkName: "settings-system" */ '../views/systems/list.vue'),
  },

  // System Create

  {
    path: '/settings/system/create',
    name: 'system-create',
    component: () => import(/* webpackChunkName: "settings-system-create" */ '../views/systems/create.vue'),
  },
  {
    path: '/settings/system/:id/edit',
    name: 'system-edit',
    component: () => import(/* webpackChunkName: "settings-system-create" */ '../views/systems/create.vue'),
  },
  //  Change log

  {
    path: '/settings/change-log',
    name: 'change-log',
    component: () => import(/* webpackChunkName: "settings-change-log" */ '../views/changeLog/list.vue'),
  },

  // contacts

  {
    path: '/settings/contacts',
    name: 'settings-contact-list',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contacts/contact-list.vue'),
  },
  {
    path: '/settings/contacts/create',
    name: 'settings-create-contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contacts/contact-detail.vue'),
  },
  {
    path: '/settings/contacts/:id/edit',
    name: 'settings-edit-contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contacts/contact-detail.vue'),
  },

  // Payment method

  {
    path: '/settings/payment-method',
    name: 'payment-method',
    component: () => import(/* webpackChunkName: "settings-payment-method" */ '../views/paymentMethod/list.vue'),
  },

  {
    path: '/settings/bank-account/add-payment-method1/:id/edit',
    name: 'add-payment-method1',
    component: () => import(/* webpackChunkName: "settings-bank-account-add-payment-method" */ '../views/paymentMethod/create.vue'),
  },

  // Add payment method1

  {
    path: '/settings/bank-account/add-payment-method',
    name: 'add-payment-method1-id',
    component: () => import(/* webpackChunkName: "settings-bank-account-add-payment-method" */ '../views/paymentMethod/create.vue'),
  },

  // Add payment method2

  {
    path: '/settings/other-payment-methods/add-payment-method',
    name: 'add-payment-method2',
    component: () => import(/* webpackChunkName: "setting-other-payment-method-add-payment-method" */ '../views/paymentMethod/createOther.vue'),
  },

  // create payment method

  // {
  //     path: '/settings/create-payment-method',
  //     name: 'create-payment-method',
  //     component: () => import(/* webpackChunkName: "unit" */ '../views/settingMenu/create-payment-method.vue')
  // },

  // Product unit

  {
    path: '/settings/product-unit',
    name: 'product-unit',
    component: () => import(/* webpackChunkName: "setting-product-unit" */ '../views/productUnits/list.vue'),
  },

  // Product unit Create

  {
    path: '/settings/product-unit/create',
    name: 'product-unit-create',
    component: () => import(/* webpackChunkName: "setting-product-unit-create" */ '../views/productUnits/create.vue'),
  },
  {
    path: '/settings/product-unit/:id/edit',
    name: 'settings-edit-unit',
    component: () => import(/* webpackChunkName: "unit" */ '../views/productUnits/create.vue'),
  },

  // add customer and dealer

  // {
  //     path: '/settings/add-customer-dealer',
  //     name: 'add-customer-dealer',
  //     component: () => import(/* webpackChunkName: "unit" */ '../views/settingMenu/add-customer-dealer.vue')
  // },

  // {
  //     path: '/settings/units/:id/edit',
  //     name: 'settings-edit-unit',
  //     component: () => import(/* webpackChunkName: "unit" */ '../views/units/unit-detail.vue')
  // },
  // projects
  {
    path: '/settings/projects',
    name: 'settings-project-list',
    component: () => import(/* webpackChunkName: "project" */ '../views/projects/project-list.vue'),
  },
  {
    path: '/settings/projects/create',
    name: 'settings-create-project',
    component: () => import(/* webpackChunkName: "project" */ '../views/projects/project-detail.vue'),
  },
  {
    path: '/settings/projects/:id/edit',
    name: 'settings-edit-project',
    component: () => import(/* webpackChunkName: "project" */ '../views/projects/project-detail.vue'),
  },

  // Certified receipt

  {
    path: '/cost/certified-receipt',
    name: 'certified-receipt-list',
    component: () => import(/* webpackChunkName: "CertifiedReceipt"*/ '../views/certifiedReceipts/list.vue'),
  },

  // Certified receipt Create

  {
    path: '/cost/certified-receipt/create',
    name: 'certified-receipt-create',
    component: () => import(/* webpackChunkName: "CertifiedReceiptCreate" */ '../views/certifiedReceipts/create.vue'),
  },

  // Certified receipt Edit

  {
    path: '/cost/certified-receipt/:id/edit',
    name: 'edit-certified-receipt',
    component: () => import(/* webpackChunkName: "CertifiedReceiptCreate" */ '../views/certifiedReceipts/create.vue'),
  },

  // Certified receipt Preview

  {
    path: '/cost/certified-receipt/preview',
    name: 'preview-certified-receipt',
    component: () => import(/* webpackChunkName: "CertifiedReceiptCreate" */ '../views/certifiedReceipts/create.vue'),
  },

  {
    path: '/cost/certified-receipt/:id/preview',
    name: 'edit-certified-receipt-preview',
    component: () => import(/* webpackChunkName: "CertifiedReceiptCreate" */ '../views/certifiedReceipts/create.vue'),
  },

  //  Payment voucher

  {
    path: '/cost/payment-voucher',
    name: 'payment-voucher',
    component: () => import(/* webpackChunkName: "csot-payment-voucher" */ '../views/paymentVoucher/list.vue'),
  },

  // Payment voucher Create

  {
    path: '/cost/payment-voucher/create',
    name: 'payment-voucher-create',
    component: () => import(/* webpackChunkName: "cost-payment-voucher-create" */ '../views/paymentVoucher/create.vue'),
  },

  // Payment voucher Edit

  {
    path: '/cost/payment-voucher/:id/edit',
    name: 'payment-voucher-edit',
    component: () => import(/* webpackChunkName: "payment" */ '../views/paymentVoucher/create.vue'),
  },
  {
    path: '/settings/payments/:id/edit',
    name: 'settings-edit-payment',
    component: () => import(/* webpackChunkName: "payment" */ '../views/certifiedReceipts/create.vue'),
  },

  // Products and service

  {
    path: '/settings/products',
    name: 'settings-product-list',
    component: () => import(/* webpackChunkName: "settings-product" */ '../views/products/list.vue'),
  },

  // Products and service Create

  {
    path: '/settings/products/create',
    name: 'settings-create-product',
    component: () => import(/* webpackChunkName: "settings-product-create" */ '../views/products/create.vue'),
  },

  {
    path: '/settings/products/:id/edit',
    name: 'settings-edit-product',
    component: () => import(/* webpackChunkName: "product" */ '../views/products/create.vue'),
  },

  // System user

  {
    path: '/settings/system-user',
    name: 'system-user',
    component: () => import(/* webpackChunkName: "setting-system-user" */ '../views/systemUser/list.vue'),
  },

  //  System user Create

  {
    path: '/settings/system-user/create',
    name: 'system-user-create',
    component: () => import(/* webpackChunkName: "settings-system-user-create" */ '../views/systemUser/create.vue'),
  },
  //  System user Edit

  {
    path: '/settings/system-user/:id/edit',
    name: 'system-user-edit',
    component: () => import(/* webpackChunkName: "settings-system-user-create" */ '../views/systemUser/edit.vue'),
  },
  //components

  {
    path: '/components/tabs',
    name: 'tabs',
    component: () => import(/* webpackChunkName: "components-tabs" */ '../views/components/tabs.vue'),
  },
  {
    path: '/components/accordions',
    name: 'accordions',
    component: () => import(/* webpackChunkName: "components-accordions" */ '../views/components/accordions.vue'),
  },
  {
    path: '/components/modals',
    name: 'modals',
    component: () => import(/* webpackChunkName: "components-modals" */ '../views/components/modals.vue'),
  },
  {
    path: '/components/cards',
    name: 'cards',
    component: () => import(/* webpackChunkName: "components-cards" */ '../views/components/cards.vue'),
  },
  {
    path: '/components/carousel',
    name: 'carousel',
    component: () => import(/* webpackChunkName: "components-carousel" */ '../views/components/carousel.vue'),
  },

  {
    path: '/components/timeline',
    name: 'timeline',
    component: () => import(/* webpackChunkName: "components-timeline" */ '../views/components/timeline.vue'),
  },
  {
    path: '/components/media-object',
    name: 'media-object',
    component: () => import(/* webpackChunkName: "components-media-object" */ '../views/components/media_object.vue'),
  },
  {
    path: '/components/list-group',
    name: 'list-group',
    component: () => import(/* webpackChunkName: "components-list-group" */ '../views/components/list_group.vue'),
  },
  {
    path: '/components/pricing-table',
    name: 'pricing-table',
    component: () => import(/* webpackChunkName: "components-pricing-table" */ '../views/components/pricing_table.vue'),
  },
  {
    path: '/components/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "components-notifications" */ '../views/components/toast.vue'),
  },

  {
    path: '/components/lightbox',
    name: 'lightbox',
    component: () => import(/* webpackChunkName: "components-lightbox" */ '../views/components/lightbox.vue'),
  },
  {
    path: '/components/countdown',
    name: 'countdown',
    component: () => import(/* webpackChunkName: "components-countdown" */ '../views/components/countdown.vue'),
  },
  {
    path: '/components/counter',
    name: 'counter',
    component: () => import(/* webpackChunkName: "components-counter" */ '../views/components/counter.vue'),
  },
  {
    path: '/components/sweetalert',
    name: 'sweetalert',
    component: () => import(/* webpackChunkName: "components-sweetalert" */ '../views/components/sweetalert.vue'),
  },

  //fonts
  {
    path: '/font-icons',
    name: 'font-icons',
    component: () => import(/* webpackChunkName: "font-icons" */ '../views/font_icons.vue'),
  },

  //pages
  {
    path: '/pages/helpdesk',
    name: 'helpdesk',
    component: () => import(/* webpackChunkName: "pages-helpdesk" */ '../views/pages/helpdesk.vue'),
  },
  {
    path: '/pages/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "pages-contact-us" */ '../views/pages/contact_us.vue'),
  },
  {
    path: '/pages/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "pages-faq" */ '../views/pages/faq.vue'),
  },
  {
    path: '/pages/faq2',
    name: 'faq2',
    component: () => import(/* webpackChunkName: "pages-faq2" */ '../views/pages/faq2.vue'),
  },
  {
    path: '/pages/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "pages-privacy-policy" */ '../views/pages/privacy_policy.vue'),
  },
  {
    path: '/pages/coming-soon',
    name: 'coming-soon',
    component: () => import(/* webpackChunkName: "pages-coming-soon" */ '../views/pages/coming_soon.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/error404',
    name: 'error404',
    component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/error404.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/error500',
    name: 'error500',
    component: () => import(/* webpackChunkName: "pages-error500" */ '../views/pages/error500.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/error503',
    name: 'error503',
    component: () => import(/* webpackChunkName: "pages-error503" */ '../views/pages/error503.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/maintenence',
    name: 'maintenence',
    component: () => import(/* webpackChunkName: "pages-maintenence" */ '../views/pages/maintenence.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/blank-page',
    name: 'blank-page',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/pages/blank_page.vue'),
  },
  {
    path: '/pages/sample',
    name: 'sample',
    component: () => import(/* webpackChunkName: "pages-sample" */ '../views/pages/sample.vue'),
  },

  //auth
  {
    path: '/auth/login-boxed',
    name: 'login-boxed',
    component: () => import(/* webpackChunkName: "auth-login-boxed" */ '../views/auth/login_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/register-boxed',
    name: 'register-boxed',
    component: () => import(/* webpackChunkName: "auth-register-boxed" */ '../views/auth/register_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/lockscreen-boxed',
    name: 'lockscreen-boxed',
    component: () => import(/* webpackChunkName: "auth-lockscreen-boxed" */ '../views/auth/lockscreen_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/pass-recovery-boxed',
    name: 'pass-recovery-boxed',
    component: () => import(/* webpackChunkName: "auth-pass-recovery-boxed" */ '../views/auth/pass_recovery_boxed.vue'),
    meta: { layout: 'auth' },
  },
  // {
  //     path: '/auth/login',
  //     name: 'login',
  //     component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
  //     meta: { layout: 'auth' }
  // },
  // {
  //     path: '/auth/register',
  //     name: 'register',
  //     component: () => import(/* webpackChunkName: "auth-register" */ '../views/auth/register.vue'),
  //     meta: { layout: 'auth' }
  // },
  {
    path: '/auth/lockscreen',
    name: 'lockscreen',
    component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/auth/lockscreen.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/pass-recovery',
    name: 'pass-recovery',
    component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/pass_recovery.vue'),
    meta: { layout: 'auth' },
  },

  //elements
  {
    path: '/elements/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "elements-alerts" */ '../views/elements/alerts.vue'),
  },
  {
    path: '/elements/avatar',
    name: 'avatar',
    component: () => import(/* webpackChunkName: "elements-avatar" */ '../views/elements/avatar.vue'),
  },
  {
    path: '/elements/badges',
    name: 'badges',
    component: () => import(/* webpackChunkName: "elements-badges" */ '../views/elements/badges.vue'),
  },
  {
    path: '/elements/breadcrumbs',
    name: 'breadcrumbs',
    component: () => import(/* webpackChunkName: "elements-breadcrumbs" */ '../views/elements/breadcrumbs.vue'),
  },
  {
    path: '/elements/buttons',
    name: 'buttons',
    component: () => import(/* webpackChunkName: "elements-buttons" */ '../views/elements/buttons.vue'),
  },
  {
    path: '/elements/buttons-group',
    name: 'buttons-group',
    component: () => import(/* webpackChunkName: "elements-buttons-group" */ '../views/elements/buttons_group.vue'),
  },
  {
    path: '/elements/color-library',
    name: 'color-library',
    component: () => import(/* webpackChunkName: "elements-color-library" */ '../views/elements/color_library.vue'),
  },
  {
    path: '/elements/dropdown',
    name: 'dropdown',
    component: () => import(/* webpackChunkName: "elements-dropdown" */ '../views/elements/dropdown.vue'),
  },
  {
    path: '/elements/infobox',
    name: 'infobox',
    component: () => import(/* webpackChunkName: "elements-infobox" */ '../views/elements/infobox.vue'),
  },
  {
    path: '/elements/jumbotron',
    name: 'jumbotron',
    component: () => import(/* webpackChunkName: "elements-jumbotron" */ '../views/elements/jumbotron.vue'),
  },
  {
    path: '/elements/loader',
    name: 'loader',
    component: () => import(/* webpackChunkName: "elements-loader" */ '../views/elements/loader.vue'),
  },
  {
    path: '/elements/pagination',
    name: 'pagination',
    component: () => import(/* webpackChunkName: "elements-pagination" */ '../views/elements/pagination.vue'),
  },
  {
    path: '/elements/popovers',
    name: 'popovers',
    component: () => import(/* webpackChunkName: "elements-popovers" */ '../views/elements/popovers.vue'),
  },
  {
    path: '/elements/progress-bar',
    name: 'progress-bar',
    component: () => import(/* webpackChunkName: "elements-progress-bar" */ '../views/elements/progress_bar.vue'),
  },
  {
    path: '/elements/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "elements-search" */ '../views/elements/search.vue'),
  },
  {
    path: '/elements/tooltips',
    name: 'tooltips',
    component: () => import(/* webpackChunkName: "elements-tooltips" */ '../views/elements/tooltips.vue'),
  },
  {
    path: '/elements/treeview',
    name: 'treeview',
    component: () => import(/* webpackChunkName: "elements-treeview" */ '../views/elements/treeview.vue'),
  },
  {
    path: '/elements/typography',
    name: 'typography',
    component: () => import(/* webpackChunkName: "elements-typography" */ '../views/elements/typography.vue'),
  },

  //tables
  {
    path: '/tables',
    name: 'tables',
    component: () => import(/* webpackChunkName: "tables" */ '../views/tables.vue'),
  },

  //users
  {
    path: '/users/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/profile.vue'),
  },
  {
    path: '/users/account-setting',
    name: 'account-setting',
    component: () => import(/* webpackChunkName: "users-account-setting" */ '../views/users/account_setting.vue'),
  },

  //drag&drop
  {
    path: '/dragndrop',
    name: 'dragndrop',
    component: () => import(/* webpackChunkName: "dragndrop" */ '../views/dragndrop.vue'),
  },

  //charts
  {
    path: '/charts/apex-chart',
    name: 'apex-chart',
    component: () => import(/* webpackChunkName: "charts-apex-chart" */ '../views/charts/apex_chart.vue'),
  },

  //widgets
  {
    path: '/widgets',
    name: 'widgets',
    component: () => import(/* webpackChunkName: "widgets" */ '../views/widgets.vue'),
  },

  //forms
  {
    path: '/forms/basic',
    name: 'basic',
    component: () => import(/* webpackChunkName: "forms-basic" */ '../views/forms/basic.vue'),
  },
  {
    path: '/forms/input-group',
    name: 'input-group',
    component: () => import(/* webpackChunkName: "forms-input-group" */ '../views/forms/input_group.vue'),
  },
  {
    path: '/forms/layouts',
    name: 'layouts',
    component: () => import(/* webpackChunkName: "forms-layouts" */ '../views/forms/layouts.vue'),
  },
  {
    path: '/forms/validation',
    name: 'validation',
    component: () => import(/* webpackChunkName: "forms-validation" */ '../views/forms/validation.vue'),
  },
  {
    path: '/forms/checkbox-radio',
    name: 'checkbox-radio',
    component: () => import(/* webpackChunkName: "forms-checkbox-radio" */ '../views/forms/checkbox_radio.vue'),
  },
  {
    path: '/forms/switches',
    name: 'switches',
    component: () => import(/* webpackChunkName: "forms-switches" */ '../views/forms/switches.vue'),
  },
  {
    path: '/forms/wizards',
    name: 'wizards',
    component: () => import(/* webpackChunkName: "forms-wizards" */ '../views/forms/wizards.vue'),
  },
  {
    path: '/forms/file-upload',
    name: 'file-upload',
    component: () => import(/* webpackChunkName: "forms-file-upload" */ '../views/forms/fileupload.vue'),
  },
  {
    path: '/forms/clipboard',
    name: 'clipboard',
    component: () => import(/* webpackChunkName: "forms-clipboard" */ '../views/forms/clipboard.vue'),
  },
  {
    path: '/forms/date-picker',
    name: 'date-picker',
    component: () => import(/* webpackChunkName: "forms-date-picker" */ '../views/forms/date_range_picker.vue'),
  },
  {
    path: '/forms/input-mask',
    name: 'input-mask',
    component: () => import(/* webpackChunkName: "forms-input-mask" */ '../views/forms/input_mask.vue'),
  },
  {
    path: '/forms/quill-editor',
    name: 'quill-editor',
    component: () => import(/* webpackChunkName: "forms-quill-editor" */ '../views/forms/quill_editor.vue'),
  },
  {
    path: '/forms/touchspin',
    name: 'touchspin',
    component: () => import(/* webpackChunkName: "forms-touchspin" */ '../views/forms/touchspin.vue'),
  },
  {
    path: '/forms/markdown-editor',
    name: 'markdown-editor',
    component: () => import(/* webpackChunkName: "forms-markdown-editor" */ '../views/forms/markdown_editor.vue'),
  },
  {
    path: '/forms/select2',
    name: 'select2',
    component: () => import(/* webpackChunkName: "forms-select2" */ '../views/forms/select2.vue'),
  },

  //apps
  {
    path: '/apps/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "apps-chat" */ '../views/apps/chat.vue'),
  },
  {
    path: '/apps/mailbox',
    name: 'mailbox',
    component: () => import(/* webpackChunkName: "apps-mailbox" */ '../views/apps/mailbox.vue'),
  },
  {
    path: '/apps/todo-list',
    name: 'todo-list',
    component: () => import(/* webpackChunkName: "apps-todo-list" */ '../views/apps/todo_list.vue'),
  },
  {
    path: '/apps/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "apps-contacts" */ '../views/apps/contacts.vue'),
  },
  {
    path: '/apps/notes',
    name: 'notes',
    component: () => import(/* webpackChunkName: "apps-notes" */ '../views/apps/notes.vue'),
  },
  {
    path: '/apps/scrumboard',
    name: 'scrumboard',
    component: () => import(/* webpackChunkName: "apps-scrumboard" */ '../views/apps/scrumboard.vue'),
  },
  {
    path: '/apps/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "apps-calendar" */ '../views/apps/calendar.vue'),
  },
  {
    path: '/apps/invoice/list',
    name: 'invoice-list',
    component: () => import(/* webpackChunkName: "apps-invoice-list" */ '../views/apps/invoice/list.vue'),
  },
  {
    path: '/apps/invoice/preview',
    name: 'invoice-preview',
    component: () => import(/* webpackChunkName: "apps-invoice-preview" */ '../views/apps/invoice/preview.vue'),
  },
  {
    path: '/apps/invoice/add',
    name: 'invoice-add',
    component: () => import(/* webpackChunkName: "apps-invoice-add" */ '../views/apps/invoice/add.vue'),
  },
  {
    path: '/apps/invoice/edit',
    name: 'invoice-edit',
    component: () => import(/* webpackChunkName: "apps-invoice-edit" */ '../views/apps/invoice/edit.vue'),
  },

  //tables
  {
    path: '/tables/basic',
    name: 'table-basic',
    component: () => import(/* webpackChunkName: "tables-basic" */ '../views/tables/basic.vue'),
  },
  {
    path: '/tables/striped',
    name: 'striped',
    component: () => import(/* webpackChunkName: "tables-striped" */ '../views/tables/striped.vue'),
  },
  {
    path: '/tables/order-sorting',
    name: 'order-sorting',
    component: () => import(/* webpackChunkName: "tables-order-sorting" */ '../views/tables/order_sorting.vue'),
  },
  {
    path: '/tables/multi-column',
    name: 'multi-column',
    component: () => import(/* webpackChunkName: "tables-multi-column" */ '../views/tables/multi_column.vue'),
  },
  {
    path: '/tables/multiple-tables',
    name: 'multiple-tables',
    component: () => import(/* webpackChunkName: "tables-multiple-tables" */ '../views/tables/multiple_tables.vue'),
  },
  {
    path: '/tables/alt-pagination',
    name: 'alt-pagination',
    component: () => import(/* webpackChunkName: "tables-alt-pagination" */ '../views/tables/alt_pagination.vue'),
  },
  {
    path: '/tables/custom',
    name: 'custom',
    component: () => import(/* webpackChunkName: "tables-custom" */ '../views/tables/custom.vue'),
  },
  {
    path: '/tables/range-search',
    name: 'range-search',
    component: () => import(/* webpackChunkName: "tables-range-search" */ '../views/tables/range_search.vue'),
  },
  {
    path: '/tables/export',
    name: 'export',
    component: () => import(/* webpackChunkName: "tables-export" */ '../views/tables/export.vue'),
  },
  {
    path: '/tables/live-dom-ordering',
    name: 'live-dom-ordering',
    component: () => import(/* webpackChunkName: "tables-live-dom-ordering" */ '../views/tables/live_dom_ordering.vue'),
  },
  {
    path: '/tables/miscellaneous',
    name: 'miscellaneous',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/tables/miscellaneous.vue'),
  },
  {
    path: '/pdfGenerate',
    name: 'pdfGen',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/generateDocuments/pdfTemplate.vue'),
  },
  {
    path: '/pdfPreview',
    name: 'pdfPreview',
    component: () => import('../views/generateDocuments/preview.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/migrateData',
    name: 'migrateData',
    component: () => import('../views/migrateData.vue'),
  },
  {
    path: '/oauth2callback',
    name: 'oauth2callback',
    component: () => import('../views/oauth2callback.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

Vue.use(VueRouterBackButton, { router });

router.beforeEach((to, from, next) => {
  const auth = JSON.parse(localStorage.getItem('token'));

  if (!auth && to.path !== '/') {
    if (to.path !== '/password-recovery' && to.path !== '/register' && !to.path.startsWith('/reset-password/')) {
      return next('/');
    }
  }

  if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
    store.commit('setLayout', 'auth');
  } else {
    store.commit('setLayout', 'app');
  }
  next(true);
});

export default router;
