const ApiRoot = process.env.VUE_APP_BASE_API_ROOT + '/api/v1';
const appConfig = {
  api: {
    login: ApiRoot + '/auth/login',
    logout: ApiRoot + '/auth/logout',
    register: ApiRoot + '/accounts/signup',
    contactType: ApiRoot + '/contact-types',
    businessType: ApiRoot + '/business-types',
    contact: ApiRoot + '/contacts',
    product: ApiRoot + '/products',
    productCategory: ApiRoot + '/product-categories',
    payment: ApiRoot + '/payments',
    project: ApiRoot + '/projects',
    unit: ApiRoot + '/units',
    user: ApiRoot + '/users',
    quotation: ApiRoot + '/quotations',
    invoice: ApiRoot + '/invoices',
    billingNote: ApiRoot + '/billingnotes',
    receipts: ApiRoot + '/receipts',
    certifiedReceipt: ApiRoot + '/certifiedreceipt',
    company: ApiRoot + '/users/',
    companyCreate: ApiRoot + '/companies/create',
    companyDelete: ApiRoot + '/companies/delete',
    companyGet: ApiRoot + '/companies',
    branch: ApiRoot + '/branches',
    documentFormat: ApiRoot + '/docFormat',
    purchaseReq: ApiRoot + '/purchaseRequisition',
    purchaseOrder: ApiRoot + '/purchaseOrder',
    goodReceipt: ApiRoot + '/goodsReceipt',
    payableNote: ApiRoot + '/payableNote',
    paymentVoucher: ApiRoot + '/paymentVoucher',
    witholdingTaxCer: ApiRoot + '/witholdingTaxCer',
    creditNote: ApiRoot + '/creditNote',
    debitNote: ApiRoot + '/debitNote',
    pendingDoc: ApiRoot + '/pendingDoc',
    reports: ApiRoot + '/reports',
    roles: ApiRoot + '/roles',
    system: ApiRoot + '/systems',
    stocks: ApiRoot + '/stocks',
    transaction: ApiRoot + '/transactions',
    email: ApiRoot + '/email',
    resetPassword: ApiRoot + '/resetPassword',
    installmentPayment: ApiRoot + '/installmentPayment',
    docHistory: ApiRoot + '/docSubmissionHistory',
    productHistory: ApiRoot + '/producthistory',
    attachFiles: ApiRoot + '/attachFiles',
    command: ApiRoot + '/command',
    etax: ApiRoot + '/etax',
    apiConfig: ApiRoot + '/apiConfig',
    countries: ApiRoot + '/countries',
    provinces: ApiRoot + '/provinces',
    districts: ApiRoot + '/districts',
    subdistricts: ApiRoot + '/subdistricts',
    revenueDepartment: ApiRoot + '/revenueDepartment',
  },
  itemsPerPage: [3, 5, 10, 20, 50, 100, 200],
  defaultItemPerPage: 10,
  vatTypes: [
    { type: 1, nameEn: 'VAT NOT included', nameTh: 'ไม่รวม VAT' },
    { type: 2, nameEn: 'VAT included', nameTh: 'รวม VAT' },
    { type: 3, nameEn: 'VAT 0%', nameTh: 'VAT 0%' },
    { type: 4, nameEn: 'VAT exempted', nameTh: 'VAT exempt' },
  ],
  paymentTypes: [
    { type: 1, nameEn: 'Expense', nameTh: 'ฝั่งค่าใช้จ่าย' },
    { type: 2, nameEn: 'Income', nameTh: 'ฝั่งขาย' },
  ],
  imageRoot: 'https://s3.ap-southeast-1.amazonaws.com/new-accistant.accrevos.com',
};

export default appConfig;
