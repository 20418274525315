export const getBranch = () => {
  let branchData = localStorage.getItem('branch');

  if (branchData) {
    branchData = JSON.parse(branchData);
  }
  return {
    branchId: branchData?.id,
    branchCode: branchData?.code,
    branchName: branchData?.name,
  };
};

export const getCompany = () => {
  const companyInfo = JSON.parse(localStorage.getItem('x-tenant-id'));

  return {
    taxIdent: companyInfo?.companyTaxIdent,
    companyAddress: companyInfo?.address,
    businessType: companyInfo?.businessType,
    companyName: companyInfo?.companyName,
    logoCompany: companyInfo?.logoCompany,
    email: companyInfo?.email,
    telephone: companyInfo?.telephone,
    eStamp: companyInfo?.eStamp,
    vatRegister: companyInfo?.vatRegister,
  };
};

export const getUser = () => {
  const userData = JSON.parse(localStorage.getItem('user'));
  return {
    userId: userData?.id,
    userFirstName: userData?.firstName,
    userLastName: userData?.lastName,
    userSignature: userData?.signature,
  };
};

export const getDocFormat = () => {
  const docFormatData = JSON.parse(localStorage.getItem('docFormat'));

  return {
    dateFormats: docFormatData?.dateFormats,
    yearType: docFormatData?.yearType,
    theme: docFormatData?.theme,
    isApprovalDate: docFormatData?.isApprovalDate,
    settingTypeNo: docFormatData?.settingTypeNo,
  };
};
