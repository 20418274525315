import '@/assets/sass/components/custom-sweetalert.scss';
import Swal from 'sweetalert2';
import i18n from '@/i18n';

export function showCreatingModal() {
  return Swal.fire({
    title: 'กำลังสร้าง',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessCreateModal() {
  return Swal.fire({
    title: 'สร้างเอกสารเสร็จสิ้น',
    icon: 'success',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showEditingModal() {
  return Swal.fire({
    title: 'กำลังแก้ไข',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessEditModal() {
  return Swal.fire({
    title: 'แก้ไขเรียบร้อย',
    icon: 'success',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showConfirmApproveModal() {
  return Swal.fire({
    title: 'กรุณายืนยันการเปลี่ยนสถานะเป็นอนุมัติ',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showConfirmWaitPaid() {
  return Swal.fire({
    title: 'กรุณายืนยันการเปลี่ยนสถานะเป็นรอชำระ',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showSuccessWaitPaid() {
  return Swal.fire({
    icon: 'success',
    title: 'ปรับสถานะรอชำระเงินแล้ว',
    timer: 2000,
  });
}

export function showConfirmCancelModal() {
  return Swal.fire({
    title: 'กรุณายืนยันการเปลี่ยนสถานะเป็นยกเลิก',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
    input: 'text', // Specify the input type as text
    inputPlaceholder: 'กรอกเหตุผล', // Placeholder text for the input field
    inputAttributes: {
      // You can specify additional attributes for the input element if needed
      autocapitalize: 'off',
    },
    showLoaderOnConfirm: true, // Display a loader animation when confirming
    preConfirm: (reason) => {
      // You can handle the input value here
      if (!reason) {
        Swal.showValidationMessage('กรุณากรอกเหตุผล');
      }
    },
  });
}

export function showApproveModal() {
  return Swal.fire({
    title: 'กำลังอนุมัติ',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessApproveModal() {
  return Swal.fire({
    icon: 'success',
    title: 'อนุมัติเสร็จสิ้น',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showConfirmReceipt() {
  return Swal.fire({
    icon: 'question',
    title: 'คุณต้องการสร้างใบเสร็จรับเงิน',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showCreatingReceipt() {
  return Swal.fire({
    title: 'กำลังสร้างใบเสร็จรับเงิน',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessCreatedReceipt() {
  return Swal.fire({
    icon: 'success',
    title: 'สร้างใบเสร็จรับเงินสำเร็จ',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showConfirmCreditNote() {
  return Swal.fire({
    icon: 'question',
    title: 'คุณต้องการสร้างใบลดหนี้',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showCreatingCreditNote() {
  return Swal.fire({
    title: 'กำลังสร้างใบลดหนี้',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessCreatedCreditNote() {
  return this.$swal.fire({
    icon: 'success',
    title: 'สร้างใบลดหนี้สำเร็จ',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showConfirmDebitNote() {
  return Swal.fire({
    icon: 'question',
    title: 'คุณต้องการสร้างใบเพิ่มหนี้',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showCreatingDebitNote() {
  return Swal.fire({
    title: 'กำลังสร้างใบเพิ่มหนี้',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showConfirmPaymentVoucher() {
  return Swal.fire({
    icon: 'question',
    title: 'คุณต้องการสร้างใบสำคัญจ่าย',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showCreatingPaymentVoucher() {
  return Swal.fire({
    title: 'กำลังสร้างใบสำคัญจ่าย',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessCreatedPaymentVoucher() {
  return Swal.fire({
    icon: 'success',
    title: 'สร้างใบใบสำคัญจ่ายสำเร็จ',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showSuccessCreatedDebitNote(type) {
  return Swal.fire({
    icon: 'success',
    title: `สร้างใบ${type === 'credit' ? 'ลด' : 'เพิ่ม'}หนี้สำเร็จ`,
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showConfirmPurchaseOrder() {
  return Swal.fire({
    icon: 'question',
    title: 'คุณต้องการสร้างใบสั่งซื้อ',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showCreatingPurchaseOrder() {
  return Swal.fire({
    title: 'กำลังสร้างใบสั่งซื้อ',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessCreatedPurchaseOrder() {
  return Swal.fire({
    icon: 'success',
    title: 'สร้างใบสั่งซื้อสำเร็จ',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showSuccessCreatedWitholding() {
  return Swal.fire({
    icon: 'success',
    title: 'สร้างใบรับรองหัก ณ ที่จ่ายสำเร็จ',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showConfirmGoodReceipt() {
  return Swal.fire({
    icon: 'question',
    title: 'คุณต้องการสร้างใบรับสินค้า',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showCreatingGoodReceipt() {
  return Swal.fire({
    title: 'กำลังสร้างใบรับสินค้า',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessCreatedGoodReceipt() {
  return Swal.fire({
    icon: 'success',
    title: 'สร้างใบรับสินค้าสำเร็จ',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showConfirmDisburment() {
  return Swal.fire({
    icon: 'question',
    title: 'คุณต้องการทำรายการเบิกจ่าย',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showCreatingDisburment() {
  return Swal.fire({
    title: 'กำลังทำรายการเบิกจ่าย',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showCancelDocument() {
  return Swal.fire({
    icon: 'success',
    title: 'ยกเลิกเอกสารแล้ว',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showSuccessCreatedDisburment() {
  return Swal.fire({
    icon: 'success',
    title: 'ทำรายการเบิกจ่ายสำเร็จ',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showSendingEmail() {
  return Swal.fire({
    title: 'กำลังส่งอีเมล์',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessSendEmail() {
  return Swal.fire({
    icon: 'success',
    title: 'สำเร็จ',
    text: 'ส่ง Email ไปแล้ว',
    // timer: 2000,
    // showConfirmButton: false,
    customClass: {
      title: 'swal-title-success',
    },
  });
}

export function invalidForm() {
  return Swal.fire({
    icon: 'error',
    title: 'ฟอร์มเอกสารไม่ถูกต้อง',
    text: 'โปรดแก้ไขรายละเอียดด้านในเอกสาร',
  });
}

export function branchNotSelected() {
  return Swal.fire({
    title: 'Invalid Form',
    text: 'โปรดเลือกสาขา',
    icon: 'error',
  });
}

export function showStatusVerified() {
  return Swal.fire({
    icon: 'warning',
    title: 'กรุณายืนยันอีเมล์',
    text: 'เพื่อเริ่มใช้บัญชี คุณต้องยืนยันที่อยู่อีเมลของคุณ',
    showConfirmButton: true,
  });
}

export function showStatusAccount() {
  return Swal.fire({
    icon: 'error',
    title: 'เกิดข้อผิดพลาด',
    text: 'Username หรือ Password ไม่ถูกต้อง',
    showConfirmButton: true,
    customClass: {
      title: 'swal-title-error',
    },
  });
}

export function showUnableUpdate() {
  return Swal.fire({
    title: 'ไม่สามารถแก้ไขได้',
    icon: 'error',
    dangerMode: true,
  });
}

export function copyToClipboard() {
  return Swal.fire({
    title: 'Copying text to clipboard',
    icon: 'success',
  });
}

export function enterEmail() {
  return Swal.fire({
    title: 'กรุณากรอกอีเมล์',
    icon: 'error',
  });
}

export function registerComplete() {
  return Swal.fire({
    icon: 'success',
    title: 'กรุณายืนยันอีเมล์',
    text: 'เพื่อเริ่มใช้บัญชี คุณต้องยืนยันที่อยู่อีเมลของคุณ',
  });
}

export function passwordChanged() {
  return Swal.fire({
    icon: 'success',
    title: 'เปลี่ยนรหัสผ่านแล้ว',
    text: `รหัสผ่านของถูกเปลี่ยนเรียบร้อยแล้ว`,
  });
}

export function errorUpdateInvoice() {
  return Swal.fire({
    icon: 'error',
    title: i18n.t('modal-message.error'),
    text: i18n.t('modal-message.stock-Insufficient'),
  });
}

export function errorUpdate(error) {
  return Swal.fire({
    icon: 'error',
    title: 'เกิดข้อผิดพลาดในการอนุมัติเอกสาร',
    text: error,
  });
}

export function requestProblem(error) {
  return Swal.fire({
    icon: 'error',
    title: 'เกิดข้อผิดพลาดในยิง request',
    text: error,
  });
}

export function somethingWrong(error) {
  return Swal.fire({
    icon: 'error',
    title: 'มีบางอย่างผิดพลาด',
    text: error,
  });
}

export function showConfirmWht() {
  return Swal.fire({
    icon: 'question',
    title: 'คุณต้องการสร้างใบรับรองภาษีหัก ณ ที่จ่าย',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function createCompanySuccess() {
  return Swal.fire({
    title: 'สร้างบริษัทสำเร็จ',
    icon: 'success',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function invalidBranchCode() {
  return Swal.fire({
    icon: 'error',
    title: 'รหัสสาขาไม่ถูกต้อง',
    text: 'กรุณาระบุรหัสสาขา 5 หลัก เช่น 00000',
  });
}

export function settingCompanySuccess() {
  return Swal.fire({
    title: 'ตั้งค่ารูปแบบเอกสารเสร็จสิ้น',
    icon: 'success',
    showConfirmButton: true,
  });
}

export function showSuccessful() {
  return Swal.fire({
    icon: 'success',
    title: 'สำเร็จ',
    showConfirmButton: false,
    timer: 2000,
  });
}

export function createCustomerSuccess() {
  return Swal.fire({
    title: 'เพิ่มชื่อลูกค้าและผู้จำหน่ายสำเร็จ',
    icon: 'success',
  });
}

export function editedCustomerSuccess() {
  return Swal.fire({
    title: 'แก้ไขชื่อลูกค้าและผู้จำหน่ายสำเร็จ',
    icon: 'success',
  });
}

export function warningTaxIDAlready() {
  return Swal.fire({
    title: 'เลขประจำตัวผู้เสียภาษีซ้ำ',
    text: 'ต้องการบันทึกข้อมูลต่อหรือไม่',
    icon: 'warning',
    showCancelButton: true,
  });
}

export function warningProductNameAlready() {
  return Swal.fire({
    title: 'ชื่อสินค้าซ้ำ',
    text: 'ต้องการบันทึกข้อมูลต่อหรือไม่',
    icon: 'warning',
    showCancelButton: true,
  });
}

export function warningProductCodeAlready() {
  return Swal.fire({
    title: 'รหัสสินค้าซ้ำ',
    text: 'ต้องการบันทึกข้อมูลต่อหรือไม่',
    icon: 'warning',
    showCancelButton: true,
  });
}

export function confirmDelete(value) {
  return Swal.fire({
    icon: 'question',
    title: 'คุณแน่ใจแล้วใช่ไหมที่จะลบ ?',
    text: value,
    showCancelButton: true,
    confirmButtonText: i18n.t('common.delete'),
    cancelButtonText: i18n.t('common.cancel'),
    padding: '2em',
  });
}

export function deleteComplete(name, type) {
  return Swal.fire({
    icon: 'success',
    title: 'ลบข้อมูลเรียบร้อย',
    text: `${type} ${name}`,
  });
}

export function Loading() {
  return Swal.fire({
    title: 'Loading...',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function OpenFile() {
  return Swal.fire({
    icon: 'success',
    title: 'Open',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function ModalUserService() {
  return Swal.fire({
    icon: 'warning',
    title: '<span style="font-size: 20px;">กรุณาติดต่อเจ้าหน้าที่ เพื่อเพิ่มผู้เข้าใช้งาน</span>',
    html: '<p style="font-size: 16px";>หมายเหตุ : ค่าบริการเพิ่ม user 1,000 บาท/ปี/user</p><p style="font-size: 16px";>(ค่าบริการยังไม่รวม VAT)</p><a href="https://lin.ee/cZI7tiq"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"></a>',
    showConfirmButton: false,
    showCloseButton: true,
  });
}

export function showConfirmEtaxInvoiceInet() {
  return Swal.fire({
    title: 'กรุณายืนยันการส่ง e-Tax Invoice ผ่าน INET หากมีการเชื่อมต่อระบบ Thebook ระบบจะส่งข้อมูลให้อัตโนมัติ',
    showCancelButton: true,
    confirmButtonText: 'ตกลง',
    cancelButtonText: 'ยกเลิก',
  });
}

export function showSendingEtaxInvoiceModal() {
  return Swal.fire({
    title: 'กำลังจัดส่งข้อมูล e-Tax Invoice',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSendingThebookModal() {
  return Swal.fire({
    title: 'กำลังจัดส่งข้อมูลไปยัง Thebook',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: '<div class="loader dual-loader mx-auto"></div>',
    customClass: {
      htmlContainer: 'overflow-hidden',
      popup: 'bg-transparent',
      title: 'text-white',
    },
  });
}

export function showSuccessEtaxInvoiceModal() {
  return Swal.fire({
    icon: 'success',
    title: 'จัดส่ง e-Tax Invoice สำเร็จ กำลังส่งข้อมูลไปยัง TheBook',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function invalidEtaxInvoiceModal() {
  return Swal.fire({
    icon: 'error',
    title: 'จัดส่ง e-Tax Invoice ไม่สำเร็จ',
  });
}

export function showSuccessThebookModal() {
  return Swal.fire({
    icon: 'success',
    title: 'จัดส่งไปยัง TheBook เรียบร้อย',
    timer: 2000,
    showConfirmButton: false,
  });
}

export function invalidThebookModal() {
  return Swal.fire({
    icon: 'error',
    title: 'จัดส่ง TheBook ไม่สำเร็จ',
  });
}

export function setUpsuccessful() {
  return Swal.fire({
    title: 'Set up successfully!',
    icon: 'success',
  });
}

export function setUpfail() {
  return Swal.fire({
    title: i18n.t('system-connect.failed-to-save-data'),
    icon: 'error',
    text: i18n.t('system-connect.unable-to-add-data'),
  });
}

export function ModalCreditTerms(date) {
  return Swal.fire({
    icon: 'warning',
    title: `${i18n.t('quotation.due-date-exceeds-credit-limit')} ${date} ${i18n.t('menu.day')}`,
  });
}

export function ModalSelectItem() {
  return Swal.fire({
    icon: 'warning',
    title: i18n.t('modal-message.error'),
    text: i18n.t('selection.please-select-an-item'),
  });
}

export function ModalAlreadyExists() {
  return Swal.fire({
    icon: 'warning',
    title: i18n.t('modal-message.error'),
    text: i18n.t('modal-message.information-already-exists'),
  });
}
