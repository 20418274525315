import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../i18n';

import auth from './auth';
import productService from '../services/product';
import unitService from '../services/unit';
import customerService from '../services/contact';
import productTypeService from '../services/product-category';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layout: 'app',
    is_show_sidebar: true,
    is_show_search: false,
    is_dark_mode: false,
    dark_mode: 'light',
    locale: null,
    menu_style: 'vertical',
    layout_style: 'full',
    countryList: [
      { code: 'en', name: 'English' },
      { code: 'th', name: 'Thai' },
    ],
    products: null,
    units: null,
    customers: null,
    selectedStartAt: null,
    selectedEndAt: null,
    branchData: JSON.parse(localStorage.getItem('branch')) || {}, // Initialize from localStorage if available
    previousBranchData: {}, // Store previous branch data
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload;
    },
    toggleSideBar(state, value) {
      state.is_show_sidebar = value;
    },
    toggleSearch(state, value) {
      state.is_show_search = value;
    },
    toggleLocale(state, value) {
      value = value || 'en';
      i18n.locale = value;
      localStorage.setItem('i18n_locale', value);
      state.locale = value;
    },

    toggleDarkMode(state, value) {
      //light|dark|system
      value = value || 'light';
      localStorage.setItem('dark_mode', value);
      state.dark_mode = value;
      if (value == 'light') {
        state.is_dark_mode = false;
      } else if (value == 'dark') {
        state.is_dark_mode = true;
      } else if (value == 'system') {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          state.is_dark_mode = true;
        } else {
          state.is_dark_mode = false;
        }
      }

      if (state.is_dark_mode) {
        document.querySelector('body').classList.add('dark');
      } else {
        document.querySelector('body').classList.remove('dark');
      }
    },

    toggleMenuStyle(state, value) {
      //horizontal|vertical|collapsible-vertical
      value = value || '';
      localStorage.setItem('menu_style', value);
      state.menu_style = value;
      if (!value || value === 'vertical') {
        state.is_show_sidebar = false;
      } else if (value === 'collapsible-vertical') {
        state.is_show_sidebar = false;
      }
    },

    toggleLayoutStyle(state, value) {
      //boxed-layout|large-boxed-layout|full
      value = value || '';
      localStorage.setItem('layout_style', value);
      state.layout_style = value;
    },
    setProduct(state, product) {
      state.products = product;
    },
    setUnit(state, units) {
      state.units = units;
    },
    setCustomer(state, customers) {
      state.customers = customers;
    },
    setProductType(state, productType) {
      state.productType = productType;
    },

    setSelectedStartAt(state, payload) {
      state.selectedStartAt = payload;
    },
    setSelectedEndAt(state, payload) {
      state.selectedEndAt = payload;
    },

    setBranchData(state, branch) {
      state.previousBranchData = { ...state.branchData }; // Save current branch as previous
      state.branchData = branch; // Update to new branch
      localStorage.setItem('branch', JSON.stringify(branch));
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },
    getSelectedStartAt: (state) => state.selectedStartAt,
    getSelectedEndAt: (state) => state.selectedEndAt,
    getPreviousBranchData: (state) => state.previousBranchData,
  },
  actions: {
    updateBranchData({ commit }, branch) {
      commit('setBranchData', branch); // Otherwise, update normally
    },

    async fetchProduct({ commit }, isFromDocument) {
      let response = await productService.getItems({
        limit: 1000,
        order_by: 'name',
        order_direction: 'desc',
        isFromDocument,
      });
      commit('setProduct', response.data.data.data);
    },

    async fetchUnit({ commit }) {
      let response = await unitService.getItems({
        limit: 1000,
        order_by: 'name',
        order_direction: 'desc',
      });
      commit('setUnit', response.data.data.data);
    },
    async fetchCustomer({ commit }) {
      let response = await customerService.getItems({
        order_by: 'name',
        order_direction: 'desc',
      });
      commit('setCustomer', response.data.data.data);
    },
    async fetchProductType({ commit }) {
      let response = await productTypeService.getItems({
        order_by: 'name',
        order_direction: 'desc',
      });
      commit('setProductType', response.data.data.data);
    },
    addStudent({ commit }, payload) {
      commit('addStudent', { payload });
    },
    addSelectedStartAt({ commit }, payload) {
      commit('setSelectedStartAt', payload);
    },
    addSelectedEndAt({ commit }, payload) {
      commit('setSelectedEndAt', payload);
    },
  },
  modules: {
    auth,
  },
});
