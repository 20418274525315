import i18n from '@/i18n';

// enums.js
export const ModeEnum = Object.freeze({
  DARK: 'dark',
  LIGHT: 'light',
  // เพิ่มค่าตามต้องการ
});

export const YearEnum = Object.freeze({
  BUDDHIST: 0,
  CHRISTIAN: 1,
});

export const BankList = Object.freeze({
  Kbank: { value: 'Kbank', label: 'bank-list.kbank' },
  SCB: { value: 'SCB', label: 'bank-list.scb' },
  UOB: { value: 'UOB', label: 'bank-list.uob' },
  KTB: { value: 'KTB', label: 'bank-list.ktb' },
  BAY: { value: 'BAY', label: 'bank-list.bay' },
  BBL: { value: 'BBL', label: 'bank-list.bbl' },
  TISCO: { value: 'TISCO', label: 'bank-list.tisco' },
  CIMBT: { value: 'CIMBT', label: 'bank-list.cimbt' },
  GSB: { value: 'GSB', label: 'bank-list.gsb' },
  TMB: { value: 'TMB', label: 'bank-list.tmb' },
  TBANK: { value: 'TBANK', label: 'bank-list.tbank' },
  OTHER: { value: 'OTHER', label: 'bank-list.other' },
});

export const PaymentType = Object.freeze({
  GET_MONEY: '0',
  PAY: '1',
  GET_PAY: '2',
  toString: (type) => {
    switch (type) {
      case '0':
        return i18n.t('sub-report.get-money');
      case '1':
        return i18n.t('sub-report.pay');
      case '2':
        return i18n.t('sub-report.get-pay');
    }
  },
});

export const ProductHistoryTypeEnum = Object.freeze({
  IN: 1,
  OUT: 2,
  toString: (type) => {
    switch (type) {
      case 1:
        return 'common.receive';
      case 2:
        return 'common.paid';
    }
  },
});

export const DocTypeEnum = Object.freeze({
  QT: 1,
  IV: 2,
  RC: 3,
  CN: 4,
  DN: 5,
  PR: 6,
  PO: 7,
  GR: 8,
  PN: 9,
  CR: 10,
  PV: 11,
  WHT: 12,
  BN: 13,
  PVS: 14,
  toString: (type) => {
    switch (type) {
      case 1:
        return 'document.quotation';
      case 2:
        return 'document.invoice';
      case 3:
        return 'document.receipt';
      case 4:
        return 'document.credit-note';
      case 5:
        return 'document.debit-note';
      case 6:
        return 'document.purchase-requisition';
      case 7:
        return 'purchase-order';
      case 8:
        return 'document.goods-receipt';
      case 9:
        return 'document.payable-note';
      case 10:
        return 'document.certified-receipt';
      case 11:
        return 'document.payment-voucher';
      case 12:
        return 'document.withholding-tax';
      case 13:
        return 'document.billing-note';
      case 14:
        return 'document.payment-voucher';
    }
  },
  toCode: (type) => {
    switch (type) {
      case DocTypeEnum.QT:
        return 'QT';
      case DocTypeEnum.IV:
        return 'IV';
      case DocTypeEnum.RC:
        return 'RC';
      case DocTypeEnum.CN:
        return 'CN';
      case DocTypeEnum.DN:
        return 'DN';
      case DocTypeEnum.PR:
        return 'PR';
      case DocTypeEnum.PO:
        return 'PO';
      case DocTypeEnum.GR:
        return 'GR';
      case DocTypeEnum.PN:
        return 'PN';
      case DocTypeEnum.CR:
        return 'CR';
      case DocTypeEnum.PV:
        return 'PV';
      case DocTypeEnum.WHT:
        return 'WHT';
      case DocTypeEnum.BN:
        return 'BN';
      case DocTypeEnum.PVS:
        return 'PVS';
      default:
        return '';
    }
  },
});

export const DocHeaderTypeEnum = Object.freeze({
  BASE: 0,
});

export const DocumentStatus = Object.freeze({
  WAITING: 0, //รอตรวจสอบ
  APPROVED: 1, //อนุมัติ
  DEPOSIT: 2, //มัดจำ
  PENDING_PAYMENT: 3, //รอชำระ
  CREATE_INVOICE: 4, //สร้างใบแจ้งหนี้
  CREATE_RECEIPT: 5, //สร้างใบเสร็จรับเงิน
  CREATE_PURCHASE_ORDER: 6, //สร้างใบสั่งซื้อ
  CREATE_PRODUCT_RECEIPT: 7, // สร้างใบรับสินค้า
  MAKE_DISBURSEMENT: 8, // ทำเบิกจ่าย
  CREATE_DEBIT_NOTE: 9, //สร้างใบเพิ่มหนี้
  CREATE_CREDIT_NOTE: 10, //สร้างใบลดหนี้
  CREATE_WHT: 11, //สร้างใบรับรองหัก ณ ที่จ่าย
  INSTALLMENT: 12, //แบ่งชำระ
  BILLING_NOTE: 13, //สร้างใบวางบิล
  PAID: 14, //จ่ายแล้ว
  APPROVED_WHT: 15, //อนุมัติใบหัก ณ ที่จ่าย
  CREATE_PAYMENT_VOUCHER: 16, // สร้างใบสำคัญจ่าย
  REMOVE_USER: 50, //ลบผู้ใช้งาน
  CANCELLED: 99, //ยกเลิก
  toString: (type) => {
    switch (type) {
      case 0:
        return 'quotation.status.wait';
      case 1:
        return 'quotation.status.approve';
      case 2:
        return 'quotation.status.deposit';
      case 3:
        return 'quotation.status.pending-payment';
      case 4:
        return 'quotation.status.generate-invoice';
      case 5:
        return 'menu.create-receipt';
      case 6:
        return 'purchase.create-purchase-order';
      case 7:
        return 'menu.create-product-receipt';
      case 8:
        return 'menu.make-disbursement';
      case 9:
        return 'quotation.document-management.create-debit-note';
      case 10:
        return 'quotation.document-management.create-credit-note';
      case 11:
        return 'cost.create-withholding-tax';
      case 12:
        return 'cost.installment-payment';
      case 13:
        return 'menu.billing-note';
      case 14:
        return 'cost.payed';
      case 15:
        return 'quotation.status.approve-witholdingTax';
      case 16:
        return 'cost.create-payment-voucher';
      case 50:
        return 'system-user-table.delete-user';
      case 99:
        return 'common.cancel';
      default:
        return 'Other';
    }
  },
});

// Can compare with == because backend return string
export const InvoiceDocHeaderTypeEnum = Object.freeze({
  INVOICE: 0,
  INVOICE_SEND_PRODUCT: 1,
  INVOICE_SEND_PRODUCT_RECEIPT: 2,
});

export const ReceiptDocHeaderTypeEnum = Object.freeze({
  RECEIPT: 0,
  RECEIPT_TAX_INVOICE: 1,
});

export const DebitNoteDocHeaderTypeEnum = Object.freeze({
  DEBIT_NOTE: 0,
  DEBIT_NOTE_TAX_INVOICE: 1,
});

export const CreditNoteDocHeaderTypeEnum = Object.freeze({
  CREDIT_NOTE: 0,
  CREDIT_NOTE_TAX_INVOICE: 1,
});

export const PaymentVoucherDocHeaderTypeEnum = Object.freeze({
  PAYMENT_VOUCHER: 0,
});

export const ReportTypeEnum = Object.freeze({
  ACCOUNT_RECEIVABLE: 1,
  ACCOUNT_PAYABLE: 2,
  toString: (type) => {
    switch (type) {
      case 1:
        return 'sub-report.Accounts-receivable-report';
      case 2:
        return 'sub-report."Trade-accounts-payable-report';
    }
  },
});

export const ReferenceCode = Object.freeze({
  INVOICE: 'IV',
});

export const DelayTime = Object.freeze({
  DEBOUNCE: 500,
});

export const DiscountTax = Object({
  SEPARATE_ITEMS: 0,
  TOTALBILL: 1,
});

export const TaxCalculation = Object.freeze({
  INCLUDETAX: 0,
  EXCLUDETAX: 1,
});

export const VAT = Object.freeze({
  VATNOTSPECIFIED: 0,
  VATREGISTERED: 1,
  VATNOTREGISTER: 2,
});

export const BusinessType = Object.freeze({
  SERVICEFIRM: 0,
  MERCHANDISINGFIRM: 1,
});

export const defaultUnit = Object.freeze([
  'ปี๊บ',
  'ซอง',
  'ดวง',
  'ดอก',
  'แผง',
  'ตลับ',
  'เที่ยว',
  'ตัว',
  'แท่น',
  'บาน',
  'ใบ',
  'ภาพ/รูป',
  'เรือน',
  'ล้อ',
  'ลัง',
  'วง',
  'เส้น',
  'หลอด',
  'หลัง',
  'เม็ด',
  'คัน',
  'เซนติเมตร',
  'ตารางเซนติเมตร',
  'กล่อง',
  'ถ้วย',
  'หีบ',
  'วัน',
  'ถัง',
  'โหล',
  'ชิ้น',
  'อย่าง',
  'ช่อ',
  'ราย',
  'ชั้น',
  'ชั่วโมง',
  'งาน',
  'กิโลกรัม',
  'กิโลเมตร',
  'ลิตร',
  'ปอนด์',
  'เมตร',
  'ตารางเมตร',
  'เครื่อง',
  'มัด',
  'ไมล์',
  'นาที',
  'แพกเกจ',
  'ม้วน',
  'ฟอง',
  'แฟ้ม',
  'ฟุต',
  'ตารางฟุต',
  'กรัม',
  'แกลลอน',
  'กลุ่ม',
  'หัว',
  'ด้าม',
  'โถ',
  'ฝา',
  'สาย',
  'ล็อต',
  'เดือน',
  'ฉบับ',
  'ออนซ์',
  'จุด',
  'คู่',
  'แพค/ห่อ',
  'แพลเลต',
  'อัน',
  'เสา',
  'แห่ง',
  'งวด',
  'คน/จำนวนคน',
  'รีม',
  'ร้าน',
  'ตัน',
  'ท่อ',
  'แท่ง',
  'ขด',
  'คิว',
  'กระสอบ',
  'กรง',
  'กรอบ',
  'กระถาง',
  'กระบอก',
  'ก้อน',
  'ต้น',
  'หน่วย',
  'สัปดาห์',
  'ปี',
  'หลา',
  'นิ้ว',
  'ถุง',
  'หีบห่อ',
  'ลูก',
  'พวง',
  'เล่ม',
  'ขวด',
  'ตู้',
  'กระป๋อง',
  'แถว',
  'เขต',
  'ผืน',
  'แผ่น',
  'ด้าน',
  'ชุด',
  'ท่อน',
  'ระบบ',
  'ครั้ง',
  'ถาด',
]);

export const docTemplateCSVLink = 'https://docs.google.com/spreadsheets/d/1yvmHmY5xtWq9RpzdVuJu8FzumBKH1P0b4_96N1q42JQ/edit?usp=sharing';
