<template>
  <div>
    <!--  BEGIN NAVBAR  -->

    <div class="header-container fixed-top">
      <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item theme-logo">
            <router-link
              to="/home-page"
              @click.native="setDefaultMenuSidebar('company')"
            >
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/new-accistant.accrevos.com/Default+Image/logo-accrevo.png"
                class="navbar-logo"
                alt="logo"
              />
            </router-link>
          </li>
        </ul>

        <div class="d-none horizontal-menu">
          <a
            href="javascript:void(0);"
            class="sidebarCollapse"
            data-placement="bottom"
            @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
          >
            <i data-feather="menu"></i>
          </a>
        </div>

        <div class="navbar-item flex-row ml-auto">
          <b-dropdown
            toggle-tag="a"
            variant="icon-only"
            toggle-class="nav-link"
            class="nav-item language-dropdown"
          >
            <template #button-content>
              <img
                v-if="selectedLang"
                :src="require(`@/assets/images/flags/${selectedLang.code}.png`)"
                class="flag-width"
                alt="flag"
              />
            </template>

            <perfect-scrollbar>
              <b-dropdown-item
                v-for="item in countryList"
                :key="item.code"
                link-class="d-flex align-items-center"
                :active="$i18n.locale === item.code"
                @click.prevent="changeLanguage(item)"
              >
                <img
                  :src="require(`@/assets/images/flags/${item.code}.png`)"
                  class="flag-width"
                  alt=""
                />

                <p>{{ item.name }}</p>
              </b-dropdown-item>
            </perfect-scrollbar>
          </b-dropdown>

          <b-dropdown
            toggle-tag="a"
            variant="icon-only"
            toggle-class="nav-link"
            class="nav-item language-dropdown"
          >
            <template #button-content>
              <i data-feather="moon"></i>
            </template>
            <b-dropdown-item @click="setTemplateStyleDark">
              <i data-feather="moon"></i>
              Dark Mode
            </b-dropdown-item>
            <b-dropdown-item @click="setTemplateStyleLight">
              <i data-feather="sun"></i>
              Light Mode
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            toggle-tag="a"
            variant="icon-only"
            toggle-class="user nav-link"
            class="nav-item user-profile-dropdown"
            :right="true"
          >
            <template #button-content>
              <div class="d-flex align-items-center">
                <img :src="profileImage" />
                <div class="line-height">
                  <p class="header-username ml-3 mt-2 my-0">{{ userName }}</p>
                  <p class="mt-0 my-0 header-username">{{ dayLeft }}</p>
                </div>
              </div>
            </template>

            <b-dropdown-item to="/settings/personal-info-setting">
              <i data-feather="user"></i>
              Profile
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item @click="handleLogout">
              <i data-feather="log-out"></i>
              logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </header>
    </div>

    <div class="sub-header-container">
      <header class="header navbar navbar-expand-sm">
        <!-- Portal vue for Breadcrumb -->
        <portal-target name="breadcrumb"> </portal-target>

        <div class="d-flex align-items-center pr-3 col-auto">
          <strong class="mr-1 text-dark">{{ $t('setting.company-name') }} : </strong>
          <p class="mt-2">{{ currentCompany }}</p>
        </div>

        <div
          class="pl-1 col-auto d-none d-sm-flex d-md-flex align-items-center"
          v-if="currentRouteName"
        >
          <strong class="text-dark">{{ $t('menu.branch') }} : </strong>
          <b-dropdown
            split
            split-variant="outline-success"
            variant="success"
            text="Split Variant Dropdown"
            class="m-2 custom-dropdown-width"
            menu-class="custom-dropdown-width"
            split-class="custom-dropdown-button-width"
            :disabled="!!$route.params.id"
          >
            <template #button-content>
              <span class="mr-1">{{ selectedBranch || $t('menu.please-select-a-branch') }}</span>
            </template>
            <b-dropdown-item @click.prevent="handleItemClick(branchName, `${$t('menu.show-all-data')}`, null, null, null, null, null)">
              {{ $t('menu.show-all-data') }}
            </b-dropdown-item>
            <b-dropdown-item
              @click.prevent="handleItemClick(item.id, item.name, item.code, item.address, item.phoneNumber, item.officeNumber, item.faxNumber)"
              v-for="item in branchName"
              :key="item.id"
              :value="selectedBranch"
              link-class="d-flex align-items-center"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
  import '@/assets/sass/custom.scss';
  import feather from 'feather-icons';
  import * as Utils from '../../utils/utils';
  import branchService from '../../services/branch';
  import userService from '../../services/user';
  import { ModeEnum } from '../../utils/enums';
  export default {
    data() {
      return {
        Utils: Utils,
        mode: ModeEnum.LIGHT,
        selectedLang: null,
        countryList: this.$store.state.countryList,
        currentCompany: this.$t('menu.no-company-list'),
        branchName: [],
        selectedBranch: 'กรุณาเลือกสาขา',
        profileImage: null,
        userName: '',
        expireDate: null,
      };
    },

    created() {
      const branch = JSON.parse(localStorage.getItem('branch'));
      if (branch) {
        this.selectedBranch = branch.name;
      }
    },

    async mounted() {
      feather.replace();
      this.selectedLang = this.$appSetting.toggleLanguage();
      let userId = JSON.parse(localStorage.getItem('user')).id;
      let res = await userService.getItem({ id: userId });

      if (!this.expireDate) this.expireDate = null;

      const firstName = res.data.data.firstName;
      const lastName = res.data.data.lastName;
      this.userName = firstName + ' ' + lastName;

      this.profileImage = JSON.parse(localStorage.getItem('user')).profileImage;
      this.toggleMode();
      this.setTemplateStyleLight();
    },

    watch: {
      $route() {
        try {
          this.getCurrentCompany();
          this.getBranchName();
        } catch (error) {
          // Handle the error
          // Check the error type
          if (error instanceof TypeError) {
            console.error('A type error occurred');
          } else if (error instanceof ReferenceError) {
            console.error('A reference error occurred');
          } else {
            console.error('An unexpected error occurred:', error);
          }
        }
      },
    },

    methods: {
      setDefaultMenuSidebar(value) {
        if (value) {
          localStorage.removeItem('x-tenant-id');
        }
      },

      setTemplateStyleDark() {
        this.$store.commit('toggleDarkMode', ModeEnum.DARK);
      },

      setTemplateStyleLight() {
        this.$store.commit('toggleDarkMode', ModeEnum.LIGHT);
      },

      handleLogout() {
        try {
          this.logout();
          this.$router.push('/login');
        } catch (err) {
          console.log(err);
        }
      },

      logout() {
        const itemsToRemove = [
          'quotation',
          'invoice',
          'billing-note',
          'receipt',
          'credit-not',
          'debit-note',
          'purchase requisition',
          'purchase-order',
          'goods-receipt',
          'payable-note',
          'certified-receipt-list',
          'payment-voucher',
          'withholding-tax',
          'docFormat',
          'user',
          'token',
          'x-tenant-id',
        ];
        itemsToRemove.forEach((item) => localStorage.removeItem(item));
      },

      toggleMode(mode) {
        this.$appSetting.toggleMode(mode);
      },

      changeLanguage(item) {
        this.selectedLang = item;
        this.$appSetting.toggleLanguage(item);
        this.$router.go();
      },

      getCurrentCompany() {
        if (localStorage.getItem('x-tenant-id')) {
          const companyInfo = JSON.parse(localStorage.getItem('x-tenant-id'));
          this.currentCompany = companyInfo.companyName;
          if (this.$route.path === '/home-page') this.currentCompany = this.$t('menu.no-company-list');

          this.expireDate = companyInfo.expireDate;
        } else {
          this.currentCompany = this.$t('menu.no-company-list');
        }
      },
      async getBranchName() {
        const currentPage = window.location.pathname.split('/').pop();
        if (localStorage.getItem('x-tenant-id') && currentPage != 'home-page' && currentPage != 'company-create') {
          let res = await branchService.getItems();
          this.branchName = res.data.data.data;
          this.selectedBranch = this.$t('menu.show-all-data');
          if (localStorage.getItem('branch')) this.selectedBranch = JSON.parse(localStorage.getItem('branch')).name;
        } else {
          this.branchName = 'กรุณาเลือกสาขา';
          this.selectedBranch = this.branchName;
          this.selectedBranch = this.$t('menu.show-all-data');
          return;
        }
      },

      handleItemClick(id, name, code, address, phoneNumber, officeNumber, faxNumber) {
        let data = { name: name, id: id, code: code, address: address, phoneNumber: phoneNumber, officeNumber: officeNumber, faxNumber: faxNumber };
        if (Array.isArray(data.id)) {
          data.id = data.id.map((branch) => branch.id);
        }
        this.$store.dispatch('updateBranchData', data);
        this.selectedBranch = name;
      },
    },

    computed: {
      currentRouteName() {
        return this.$route.name !== 'home-page' && this.$route.name !== 'company-create';
      },
      dayLeft() {
        if (this.expireDate) {
          const expireDate = new Date(this.expireDate);
          const currentDate = new Date();
          let Difference_In_Time = expireDate.getTime() - currentDate.getTime();

          // Calculating the no. of days between two dates
          let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

          return Difference_In_Days > Difference_In_Time ? `${this.$t('menu.expired')}` : `${this.$t('menu.day-left')}\n ${this.Utils.formatDays.call(this, Difference_In_Days)}`;
        } else {
          return this.$t('menu.no-expireDate');
        }
      },
    },
  };
</script>

<style scoped>
  .line-height {
    line-height: 20px;
  }
</style>
